import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import '../components/styles/seance.css';
import Layout from '../components/layout';
import HeaderRandom from '../components/headerRandom';
import Seo from '../components/seo';

const Seance = () => (
  <Layout>
    <Seo />
    <HeaderRandom />
    <div id='title'>
      <h1>Séance</h1>
    </div>
    <div className='division'>
      <div className='container illustration'>
        <div className='col-md-4 col-sm-4 col-xs-4 flex'>
          <StaticImage src='../img/sad.png' width={64} height={64} className='center block' placeholder='dominantColor' alt='sad' />
        </div>
        <div className='col-md-4 col-sm-4 col-xs-4 flex'>
          <StaticImage src='../img/armchair.png' width={64} height={64} className='center block' placeholder='dominantColor' alt='armchair' />
        </div>
        <div className='col-md-4 col-sm-4 col-xs-4 flex'>
          <StaticImage src='../img/happy.png' width={64} height={64} className='center block' placeholder='dominantColor' alt='happy' />
        </div>
      </div>
    </div>

    <div className='container'>
      <div className='row'>
        <div className='toggle'>
          <div className='col-md-5'>
            <h2 className='title'>DÉROULEMENT D’UNE SÉANCE </h2>
            <p>La séance d’hypnose en elle-même</p>
            <p>1- Il s’agit de faire connaissance, et de recueillir les informations sur ce qui vous amène à consulter, sur vos objectifs.</p>
            <p>2- La séance d’hypnose en elle même est un beau voyage à l intérieur de soi où je serai votre guide.</p>
            <p>3- Retour à l’état de veille en pleine conscience.</p>
            <p>Dans ma pratique, j’ai une préférence pour  l’hypnose intégrative. </p>
          </div>
          <div className='col-md-7'>
            <StaticImage src='../img/seance.jpg' placeholder='blurred' alt='seance' width={653} height={436} />
          </div>
        </div>
      </div>
    </div>

    <div>
      <div>
        <div className='container division'>
          <div className='row'>
            <div className='col-md-12'>
              <h2 className='title'>L'hypnose intégrative &reg;</h2>
              <p>Bien que pratiquant l’hypnose Ericksonienne, mon orientation est tournée vers l’ hypnose Intégrative.</p>
              <p>« Née en France, à Nice il y a plus d’une dizaine d’années, Hypnose Intégrative® est centrée sur le codage affectif et émotionnel des constructions singulières de réalités individuelles et collectives. Cette approche hypnotique ouvre à de nouvelles formes de communication intra et interpersonnelles. Ces formes nouvelles permettent de se libérer de traumatismes et de deuils compliqués ou pathologiques ; mais pas seulement. Hautement personnalisée et respectueuse Hypnose Intégrative® engendre un réaménagement et une transformation des états internes. Ce travail profond favorise une relance des processus psychiques indispensables à équilibre et épanouissement. »</p>
              <p className='t-right'>G.ETTZEVOGLOV (père de l’approche)</p>
              <p><u><b>L'hypnose thérapeutique ne se substitue pas à un avis médical ou un traitement médicamenteux.</b></u></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Seance;
